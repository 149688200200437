import { Link } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cn } from '#app/utils/misc.js'
import { Button } from './ui/button'

export function CookieConsent({
	onAcceptCallback = () => {},
	onDeclineCallback = () => {},
}) {
	const [isOpen, setIsOpen] = useState(false)
	const [hide, setHide] = useState(false)
	const { t } = useTranslation()

	const accept = () => {
		setIsOpen(false)
		document.cookie =
			'cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT'
		setTimeout(() => {
			setHide(true)
		}, 700)
		onAcceptCallback()
	}

	const decline = () => {
		setIsOpen(false)
		setTimeout(() => {
			setHide(true)
		}, 700)
		onDeclineCallback()
	}

	useEffect(() => {
		try {
			setIsOpen(true)
			if (document.cookie.includes('cookieConsent=true')) {
				setIsOpen(false)
				setTimeout(() => {
					setHide(true)
				}, 700)
			}
		} catch (error) {
			console.log({ error })
		}
	}, [])

	return (
		<div
			className={cn(
				'fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md',
				!isOpen
					? 'translate-y-8 opacity-0 transition-[opacity,transform]'
					: 'translate-y-0 opacity-100 transition-[opacity,transform]',
				hide && 'hidden',
			)}
		>
			<div className="m-3 rounded-md border border-border bg-background shadow-lg dark:bg-card">
				<div className="grid gap-2">
					<div className="flex h-14 items-center justify-between border-b border-border p-4">
						<h1 className="text-lg font-medium">{t('cookieConsent.title')}</h1>
					</div>
					<div className="p-4">
						<p className="text-start text-sm font-normal">
							{t('cookieConsent.description')}
							<br />
							<span className="text-xs">
								{t('cookieConsent.agreement')}
							</span>{' '}
							<Link to="/cookies" className="text-xs underline">
								{t('cookieConsent.learnMore')}
							</Link>
						</p>
					</div>
					<div className="flex gap-2 border-t border-border p-4 py-5 dark:bg-background/20">
						<Button onClick={accept} className="w-full">
							{t('cookieConsent.accept')}
						</Button>
						<Button onClick={decline} className="w-full" variant="secondary">
							{t('cookieConsent.decline')}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
